import React, { useState } from "react"
import Moment from "react-moment"
import { rhythm } from "../utils/typography"
import TextInputWithEmoji from "./textInputWithEmoji.js"
const backend = require("../helpers/devsurvival.api.js")

export default ({
  _id,
  comment,
  name,
  author,
  thumbsDown,
  thumbsUp,
  createdAt: date,
  parentID,
  postTitle,
  comments,
}) => {
  const [stats, setStats] = useState({ thumbsDown, thumbsUp })
  const [hidden, setHidden] = useState(true)
  const [formState, setFormState] = useState({
    author: "",
    comment: "",
    email: "",
  })
  const initialChildComments = comments.filter(c => c.parentID === _id)
  const [childComments, setChildComments] = useState(initialChildComments)

  const addLike = async e => {
    const comment = await backend.updateComment({
      _id,
      thumbsUp: stats.thumbsUp + 1,
    })
    setStats({ ...stats, thumbsUp: comment.thumbsUp })
  }
  const addDislike = async e => {
    const comment = await backend.updateComment({
      _id,
      thumbsDown: stats.thumbsDown + 1,
    })
    setStats({ ...stats, thumbsDown: comment.thumbsDown })
  }

  const toggleReplyForm = e => {
    setHidden(prev => !prev)
  }

  const createReply = async e => {
    e.preventDefault()
    const reply = await backend.addComment({
      parentID: _id,
      ...formState,
      postTitle,
    })
    setChildComments([reply, ...childComments])
  }
  const onChange = e => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }
  const [numberOfChildComments, setNumberOfChildComments] = useState(2)

  const showMoreChildComments = e => {
    setNumberOfChildComments(numberOfChildComments * 2)
  }
  const showLessChildComments = e => {
    setNumberOfChildComments(numberOfChildComments / 2)
  }
  return (
    <div className="flex-column-start comment">
      <p className="comment-header">
        <b>{author ? author.name : name} </b>{" "}
        <small>
          <Moment className="muted" format="MMMM DD, YYYY h:mm a">
            {date}
          </Moment>
        </small>{" "}
        <span className="comment-action-bar">
          {stats.thumbsUp}
          <span
            className="emoji"
            role="img"
            aria-label="thumbs up"
            onClick={addLike}
          >
            👍
          </span>{" "}
          {stats.thumbsDown}
          <span
            className="emoji"
            role="img"
            aria-label="thumbs down"
            onClick={addDislike}
          >
            👎
          </span>
        </span>
        {!parentID ? <a onClick={toggleReplyForm}>reply</a> : ""}
      </p>
      <div className="comment-text">{comment}</div>
      <div className="child-comments-container">
        {childComments.slice(0, numberOfChildComments).map((c, i) => (
          <CommentChild key={i} {...c} postTitle={postTitle} />
        ))}
        <div className="flex-row-between">
          {numberOfChildComments <= 2 ? (
            ""
          ) : (
            <a onClick={showLessChildComments}>less...</a>
          )}
          {numberOfChildComments >= childComments.length ? (
            ""
          ) : (
            <a onClick={showMoreChildComments}>more...</a>
          )}
        </div>
      </div>
      {hidden ? (
        ""
      ) : (
        <form onSubmit={createReply}>
          <input
            type="text"
            value={formState.author}
            name="author"
            placeholder="Name*"
            onChange={onChange}
            required
          />
          <input
            type="email"
            value={formState.email}
            name="email"
            placeholder="Email*"
            onChange={onChange}
            required
          />
          <TextInputWithEmoji
            value={formState.comment}
            name="comment"
            placeholder="Say something back"
            onChange={onChange}
            required
          />
          <button type="submit">Reply</button>
        </form>
      )}
      <hr
        style={{
          marginTop: rhythm(1),
        }}
      />
    </div>
  )
}

const CommentChild = ({
  _id,
  comment,
  author,
  name,
  thumbsDown,
  thumbsUp,
  createdAt: date,
  parentID,
  postTitle,
}) => {
  const [stats, setStats] = useState({ thumbsDown, thumbsUp })

  const addLike = async e => {
    const comment = await backend.updateComment({
      _id,
      thumbsUp: stats.thumbsUp + 1,
    })
    setStats({ ...stats, thumbsUp: comment.thumbsUp })
  }
  const addDislike = async e => {
    const comment = await backend.updateComment({
      _id,
      thumbsDown: stats.thumbsDown + 1,
    })
    setStats({ ...stats, thumbsDown: comment.thumbsDown })
  }
  return (
    <div className="flex-column-start comment">
      <p className="comment-header">
        <b>{author ? author.name : name} </b>{" "}
        <small>
          <Moment className="muted" format="MMMM DD, YYYY h:mm a">
            {date}
          </Moment>
        </small>{" "}
        <span className="comment-action-bar">
          {stats.thumbsUp}
          <span
            className="emoji"
            role="img"
            aria-label="thumbs up"
            onClick={addLike}
          >
            👍
          </span>{" "}
          {stats.thumbsDown}
          <span
            className="emoji"
            role="img"
            aria-label="thumbs down"
            onClick={addDislike}
          >
            👎
          </span>
        </span>
      </p>
      <div className="comment-text">{comment}</div>
    </div>
  )
}
