import React, { useState, useEffect } from "react"
const backend = require("../helpers/devsurvival.api.js")

export default ({ name, placeholder, value, required, onChange }) => {
  const [emojis, setEmojis] = useState([])

  useEffect(() => {
    backend.getEmojis().then(e => setEmojis(e))
  }, [])
  const setValue = e => {
    let updatedValue = e.target.value
    emojis.forEach(emo => {
      var re = new RegExp(emo.tag, "g")
      updatedValue = updatedValue.replace(re, emo.value)
    })
    onChange({ target: { name, value: updatedValue } })
  }
  return (
    <input
      type="text"
      required={required ? true : false}
      placeholder={placeholder}
      value={value}
      name={name}
      onChange={setValue}
    />
  )
}
