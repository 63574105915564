import React from "react"
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  RedditShareButton,
  PinterestShareButton,
  EmailShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  OKIcon,
  PinterestIcon,
  RedditIcon,
  TwitterIcon,
} from "react-share"

import { useStaticQuery, graphql, Link } from "gatsby"

import { MdEmail } from "react-icons/md"

export default function ShareBar({ url, location }) {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          social {
            twitter
          }
          siteUrl
        }
      }
      allImageSharp(
        filter: { fluid: { originalName: { eq: "campfire.png" } } }
      ) {
        edges {
          node {
            fluid {
              src
              originalName
            }
          }
        }
      }
    }
  `)

  const title = data.site.siteMetadata.title
  const siteUrl = data.site.siteMetadata.siteUrl
  const { twitter } = data.site.siteMetadata.social
  const imgSrc = `${siteUrl}${data.allImageSharp.edges[0].node.fluid.src}`
  return (
    <div className="share-bar">
      <div className="flex-row-start">
        <h4>Share </h4>
        <FacebookShareButton
          url={url}
          className="button is-outlined is-rounded facebook"
        >
          <FacebookIcon size={25} />
        </FacebookShareButton>
        <TwitterShareButton
          url={url}
          className="button is-outlined is-rounded twitter"
          title={title}
          via={twitter.split("@").join("")}
          // hashtags={tags}
        >
          <TwitterIcon size={25} />
        </TwitterShareButton>

        <LinkedinShareButton
          url={url}
          className="button is-outlined is-rounded linkedin"
          title={title}
        >
          <LinkedinIcon size={25} />
        </LinkedinShareButton>
        <RedditShareButton
          url={url}
          className="button is-outlined is-rounded reddit"
          title={title}
        >
          <RedditIcon size={25} />
        </RedditShareButton>
        <PinterestShareButton
          url={url}
          className="button is-outlined is-rounded reddit"
          title={title}
          media={imgSrc}
        >
          <PinterestIcon size={25} />
        </PinterestShareButton>
        <EmailShareButton
          url={url}
          className="button is-outlined is-rounded reddit"
          title={title}
        >
          <EmailIcon size={25} />
        </EmailShareButton>
      </div>
    </div>
  )
}
