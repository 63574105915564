import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import CommentSection from "../components/commentSection.js"
import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import { ClipLoader as CircleLoader } from "react-spinners"
import ShareBar from "../components/shareBar"
import { FaTag, FaEye, FaHeart } from "react-icons/fa"
const backend = require("../helpers/devsurvival.api.js")

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  //const { previous, next } = pageContext
  const posts = data.allMarkdownRemark.edges
  const [postStat, setPostStat] = useState({ views: 0, stars: 0 })
  const [statLoading, setStatLoading] = useState(false)
  const similarPosts = posts.filter(
    ({ node }) =>
      node.frontmatter.title !== post.frontmatter.title &&
      node.frontmatter.tags.some(t => post.frontmatter.tags.includes(t))
  )
  const addStar = async e => {
    const updatedStat = await backend.updatePostStat({
      ...postStat,
      stars: postStat.stars + 1,
    })
    setPostStat(updatedStat)
  }
  useEffect(() => {
    // fetching the posts stats when the page loads
    setStatLoading(true)
    backend.createPostStat(post.frontmatter.title).then(stats => {
      backend
        .updatePostStat({ ...stats, views: stats.views + 1 })
        .then(setPostStat) // setting the updated postStats
        .catch(err => setPostStat(stats)) // if failed to update, update the last postStat;
        .finally(() => setTimeout(() => setStatLoading(false), 150))
    })
  }, [])
  return (
    <Layout location={location} title={siteTitle} editable>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        canonical_url={post.frontmatter.canonical_url || ""}
      />

      <div className="blog-container">
        <article>
          {/* HEADER */}
          <header className="post-header">
            <h2>{post.frontmatter.title}</h2>
            <p>
              {post.frontmatter.author} · {post.frontmatter.date} ·{" "}
              {post.timeToRead} min read
            </p>
            <p style={{ margin: 0 }}>
              {post.frontmatter.tags.map((t, i) => (
                <Link
                  style={{ marginRight: 10 }}
                  key={i}
                  to="/archive/"
                  state={{ topic: t }}
                >
                  <FaTag color="FFD679" />
                  {t}
                </Link>
              ))}
            </p>

            <div className="post-stat">
              {statLoading ? (
                <CircleLoader size={20} />
              ) : (
                [
                  <p key="views" className="stat-value">
                    {postStat.views} <FaEye />
                  </p>,
                  <p
                    key="stars"
                    style={{ marginLeft: "10px" }}
                    className="stat-value"
                  >
                    {postStat.stars}
                    <span onClick={addStar}>
                      {" "}
                      <FaHeart color="#C62828" />
                    </span>
                    <small className="muted">leave some love!</small>
                  </p>,
                ]
              )}
            </div>
          </header>
          <div className="flex-column-start" style={{ paddingLeft: "3vw" }}>
            <Link to={`${location.pathname}/edit/`}> Suggest Edit</Link>
            {post.frontmatter.canonical_url ? (
              <p>
                {" "}
                <a href={post.frontmatter.canonical_url}>
                  link to original post
                </a>
              </p>
            ) : (
              ""
            )}
            {post.frontmatter.github ? (
              <a
                href={post.frontmatter.github}
                target="_blank"
                rel="noopener noreferrer"
              >
                get source code
              </a>
            ) : (
              ""
            )}
          </div>

          {/* POST BODY */}
          <section
            className="blog-body"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />

          <hr
            style={{
              marginTop: rhythm(1),
            }}
          />
          <ShareBar location={location} url={location.href} />

          <CommentSection postTitle={post.frontmatter.title} />

          {/*  FOOTER */}
          <footer>
            {post.frontmatter.author.toLowerCase().includes("hieu") ? (
              <Bio />
            ) : (
              <div className="bio">
                <b>{post.frontmatter.author}</b> -
                <span>{post.frontmatter.personal_description}</span>
                {post.frontmatter.socials ? (
                  <div>
                    Follow me:
                    {post.frontmatter.socials.map((s, i) => (
                      <a style={{ marginRight: 10 }} key={i} href={s.url}>
                        {s.label}
                      </a>
                    ))}
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          </footer>
        </article>

        <nav>
          {similarPosts && similarPosts.length > 0 ? (
            <div style={{ marginTop: 15 }}>
              <h4> Similar Posts:</h4>
              <ul className="similar-posts">
                {similarPosts.slice(0, 5).map(({ node }, i) => (
                  <li key={i}>
                    <Link to={node.fields.slug}>
                      {node.frontmatter.title} <FaTag color="FFD679" />
                      {node.frontmatter.tags[0]}
                    </Link>{" "}
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            ""
          )}
        </nav>
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
          }
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
        author
        socials {
          label
          url
        }
        personal_description
        canonical_url
        github
      }
      timeToRead
    }
  }
`
