import React, { useState, useEffect } from "react"
import Comment from "./comment"
import { HashLoader } from "react-spinners"
import TextInputWithEmoji from "../components/textInputWithEmoji.js"
import { MdChat } from "react-icons/md"
const backend = require("../helpers/devsurvival.api.js")

export default ({ data, postTitle }) => {
  const [formState, setFormState] = useState({
    author: "",
    comment: "",
    email: "",
  })
  const [loading, setLoading] = useState(false)
  const [comments, setComments] = useState([])
  const [hidden, setHidden] = useState(true)
  const onChange = e => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  // fetching comments for posts when the page loads
  useEffect(() => {
    setLoading(true)
    backend
      .getComments(postTitle)
      .then(setComments)
      .catch(() => {})
      .finally(() => setLoading(false))
  }, [])

  const submit = async e => {
    e.preventDefault()
    setLoading(true)
    await backend.addComment({ ...formState, postTitle })
    const comments = await backend.getComments(postTitle)
    setComments(comments)
    setTimeout(async () => {
      setLoading(false)
    }, 500)
  }

  const toggleDiscussion = async e => {
    setHidden(prev => !prev)
    try {
      const comments = await backend.getComments(postTitle)
      setComments(comments)
    } catch (err) {
      //console.log(err)
    }
  }

  return (
    <div className="comment-section">
      <button onClick={toggleDiscussion}>
        {" "}
        <MdChat size={16} /> Discussion ({comments.length})
      </button>
      {hidden ? (
        ""
      ) : (
        <div>
          {loading ? (
            <HashLoader />
          ) : (
            <div>
              <form
                className="flex-column-start comment-form"
                onSubmit={submit}
              >
                <input
                  type="text"
                  value={formState.author}
                  placeholder="Name*"
                  onChange={onChange}
                  name="author"
                  required
                />
                <input
                  type="email"
                  value={formState.email}
                  placeholder="Email*"
                  onChange={onChange}
                  name="email"
                  required
                />
                <TextInputWithEmoji
                  placeholder={`${
                    comments.length === 0 ? "Be the first one to post! " : ""
                  }Tip: type '#smile' to show 😀. For complete list please go to the devsurvival user guide`}
                  required
                  onChange={onChange}
                  name="comment"
                  value={formState.comment}
                />

                <div className="flex-row">
                  <button type="submit">Comment </button>
                  <span style={{ marginLeft: "10px" }}>
                    <a
                      target="_blank"
                      href="/devsurvival-user-guide/"
                      rel="noopener noreferrer"
                    >
                      ❓
                    </a>
                  </span>
                </div>
              </form>
              {comments.length === 0 ? (
                ""
              ) : (
                <div>
                  {loading ? (
                    ""
                  ) : (
                    <div>
                      {comments
                        .filter(c => (c.parentID ? false : true))
                        .map((c, i) => (
                          <Comment
                            key={i}
                            {...c}
                            postTitle={postTitle}
                            comments={comments}
                          />
                        ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
